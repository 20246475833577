
import { Component, Vue } from 'vue-property-decorator';
import { CrTitle } from "@pxc/crisp-oss-ui-components";

@Component({
  components: {
    CrTitle
  }
})

export default class Footer extends Vue {

}

