<template>
  <div id="app" class="container-fluid">

    <div>

      <div class="banner-area">
        <picture>
          <img src="img/00172711_STANDARD_PICTURE_15_SRGB.jpg" alt="" class="banner">
        </picture>
      </div>

      <div class="home mb-5 text-left ">

        <div class="row">
          <div class="col-0 col-lg-2"></div>
          <div class="col-12 col-lg-8">


            <!--<CrTitle title="Projektierung Fernwirk- und Schutzschrank" :heading="1" class="mt-4 mb-5 text-left"></CrTitle>-->
            <!--<div class="col-0 col-lg-2 col-xxl-3"></div>
            <div class="col-12 col-lg-8 col-xxl-6">-->


            <CrTitle title="Wechselrichter wählen" :heading="3" class="mt-5"></CrTitle>
            <p class="white-space">{{ subTitle }}</p>
            <div class="row">
              <div class="col-3 text-left pt-2">Hersteller</div>
              <div class="col-9" />
            </div>
            <div class="row ps-5">
              <div class="col-12">
                <CrRadioButton v-for="(manufacturer, i) of myData" v-model="selector.inverterManufacturer" :key="manufacturer.name" :id="manufacturer.name" :value="i" name="invertermanufacturer">{{manufacturer.name}}</CrRadioButton>
              </div>
              <div class="col-12">
                <span>Hersteller fehlt? </span>
                <a :href="mailHersteller">Kontakt aufnehmen </a>
              </div>
            </div>


            <template v-if="selector.inverterManufacturer !== ''">

              <div class="row mt-3">
                <div class="col-3 text-left pt-2">Typ</div>
                <div class="col-9" />
              </div>
              <div class="row ps-5">

                <div class="col-12 pt-2">

                  <div class="row mb-3" :key="phase.name" v-for="(phase, i) of myData[selector.inverterManufacturer].phases">
                    <div class="col-12"><b>{{phase.name}}</b></div>
                    <div class="col-12">
                      <div class="ps-2">
                        <CrRadioButton v-for="(type, j) of phase.types" v-model="selector.inverterType" :key="type.name" :id="type.name" :value="`${i}-${j}`" name="invertertype">{{type.name}}</CrRadioButton>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <span>Typ fehlt? </span>
                    <a :href="mailTyp">Kontakt aufnehmen </a>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="selector.inverterType !== ''">
              <CrTitle title="Wechselrichter-Spezifikationen" :heading="3" class="mt-3"></CrTitle>

              <CrTable class="mb-2">
                <CrTableBody>
                  <tr>
                    <td>Hersteller</td>
                    <td>{{myData[selector.inverterManufacturer].name}}</td>
                  </tr>
                  <tr>
                    <td>Typ</td>
                    <td>{{myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].name}}</td>
                  </tr>

                  <tr>
                    <td>Max. Leerlaufspannung</td>
                    <td>{{myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].voltage}}</td>
                  </tr>

                  <tr>
                    <td>Anzahl unabhängiger MPP-Tracker</td>
                    <td>{{myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].mppt}}</td>
                  </tr>
                  <tr>
                    <td>Anzahl der Strings je MPP-Tracker</td>
                    <td>{{myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].strings}}</td>
                  </tr>
                  <tr>
                    <td>Max. nutzbarer Strom je MPP-Tracker</td>
                    <td>{{myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].max}}</td>
                  </tr>
                  <tr>
                    <td title="Manche Wechselrichter erlauben das Sammeln von mehreren Parallel geschalteten Strings im GAK, sodass diese dann gesammelt mit einem String zum Wechselrichter geführt werden können. Manchmal wird dieses Vorgehen jedoch vom Hersteller nicht empfohlen oder ist sogar technisch nicht zulässig." >Min. erforderliche Eingangspaare (+/-) je MPP-Tracker
                      <CrIcon class="d-inline-block" icon="info"></CrIcon>
                    </td>
                    <td>{{myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].min}}</td>
                  </tr>
                </CrTableBody>
              </CrTable>
              <CrAlert v-if="myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].info" severity="warning" >
                <div v-html="myData[selector.inverterManufacturer].phases[selectedPhase].types[selectedType].info"></div>
              </CrAlert>
              <div class="row mt-1 mb-2">
                <div class="col-3"></div>
                <div class="col-9 text-right">
                  <span>Fehler gefunden? </span>
                  <a :href="mailFehler">Kontakt aufnehmen </a>
                </div>
              </div>

              <CrAlert   severity="warning" >
                <CrCheckbox v-model="specificationsChecked" :disabled="specificationsChecked" id="specificationsChecked">Die Typkompatibilität des PV-Sets bzw. GAKs ergibt sich aus den technischen Spezifikationen des Wechselrichters. Allerdings kann der Wechselrichterhersteller die technischen Eigenschaften seiner Produkte jederzeit ändern. Auch der Einsatz von Hochleistungsmodulen in der PV-Anlage kann dazu führen, dass sich die Anzahl der Strings in der Praxis von den hier angegebenen Werten unterscheidet. Durch Setzen des Hakens bestätigen Sie, dass Sie die technischen Daten überprüft haben.</CrCheckbox>
              </CrAlert>

              <CrButton v-if="!(specificationsChecked && selectionFinished)" variant="primary" class="mt-3 w-100" :disabled="!specificationsChecked" @click="finishSelection">Selektion abschließen</CrButton>
              <div v-if="selectionFinished">
                <CrTitle title="Produktliste" :heading="3" class="mt-3"></CrTitle>

                <div class="accordion mb-3" :class="{ 'accordion-open': filter.active }">
                  <div class="accordion-header p-3" @click="filter.active = !filter.active">
                    <CrIcon icon="scrollLeft" class="accordion-ico d-inline-block" />
                    <CrTitle title="Filteroptionen" :heading="4" class="ms-4 d-inline-block" />
                  </div>
                  <div class="accordion-body">
                    <!--<div v-if="filter.active" class="filter row">-->
                    <div v-if="filter.active" class="filter">
                      <!--<div class="col-12 col-md-6 col-xl-4">-->
                      <div class="px-2">
                        <CrTitle title="Leitungseinführung" :heading="5" />
                        <CrRadioButton name="kabeleinfuehrung" value="Sunclix" v-model="filter.kabeleinführung"  id="filterSunclix">Sunclix</CrRadioButton>
                        <CrRadioButton name="Kabelverschraubung" value="Kabelverschraubung" v-model="filter.kabeleinführung"  id="filterKabelverschraubung">Kabelverschraubung</CrRadioButton>
                        <CrCheckbox v-model="filter.erweiterterAnschluss"  id="filterErweiterterAnschluss">Erweiterter Anschlussquerschnitt<CrIcon class="d-inline-block ml-2" title="Bei Anschlussquerschnitten > 6 mm² aktivieren Sie dieses Auswahlfeld. Einige PV-Sets ermöglichen Anschlussquerschnitte von bis zu 16 mm²" icon="info" /></CrCheckbox>
                      </div>
                      <!--<div class="col-12 col-md-6 col-xl-4">-->
                      <div class="px-2">
                        <CrTitle title="Ableiterklasse nach IEC" :heading="5" />
                        <CrRadioButton name="ableiterklasse" value="T1/T2" v-model="filter.ableiterklasse" id="filtert1t2">T1/T2</CrRadioButton>
                        <CrRadioButton name="ableiterklasse" value="T2" v-model="filter.ableiterklasse" id="filtert2">T2</CrRadioButton>
                        <CrRadioButton name="ableiterklasse" value="Irrelevant" v-model="filter.ableiterklasse" id="filtertegal">Irrelevant</CrRadioButton>
                      </div>
                      <!--<div class="col-12 col-md-6 col-xl-4">-->
                      <div class="px-2">
                        <CrTitle title="DC-Freischaltung" :heading="5" />
                        <CrRadioButton v-model="filter.dcfreischaltung" name="dcfreischaltung" value="DC-Lasttrennschalter" id="filterDcLastTrenn">DC-Lasttrennschalter</CrRadioButton>
                        <CrRadioButton v-model="filter.dcfreischaltung" name="dcfreischaltung" value="DC-Feuerwehrschalter (Motorbetrieben)" id="filterDcFeuerwehrMotor">DC-Feuerwehrschalter (Motorbetrieben)<CrIcon class="d-inline-block" title="Durch das unterbrechen einer 230V AC Stromversorgung mittels Not-Aus-Schalter können diese Modelle die DC-Quelle sicher trennen. Eine motorisierte Wiedereinschaltung bei Wiederherstellung der Stromversorgung macht das manuelle Wiedereinschalten überflüssig." icon="info" /></CrRadioButton>
                        <CrRadioButton v-model="filter.dcfreischaltung" name="dcfreischaltung" value="DC-Feuerwehrschalter (Unterspannung)" id="filterDcFeuerwehrUnter">DC-Feuerwehrschalter (Unterspannung)<CrIcon class="d-inline-block" title="Durch das unterbrechen einer 230V AC Stromversorgung mittels Not-Aus-Schalter können diese Modelle die DC-Quelle sicher trennen. Der Schalter muss Manuell wieder eingeschaltet werden." icon="info" /></CrRadioButton>
                        <CrRadioButton v-model="filter.dcfreischaltung" name="dcfreischaltung" value="Keine" id="filterDcnull">Keine</CrRadioButton>
                      </div>
                      <!--<div class="col-12 col-md-6 col-xl-4">-->
                      <div class="px-2">
                        <CrTitle title="Modul- & Leitungsschutz" :heading="5" />
                        <CrRadioButton v-model="filter.sicherungen" name="pvSicherungen" :value="true" id="filterSicherungen">PV-Sicherungen</CrRadioButton>
                        <CrRadioButton v-model="filter.sicherungen" name="pvSicherungen" :value="false" id="filterSicherungen1">Keine</CrRadioButton>
                      </div>
                    </div>
                  </div>
                </div>



                <template v-if="filteredProducts.length === 0">
                  <div v-if="filter.active">
                    Es konnten keine Produkte mit diesen Filteroptionen gefunden.
                    <a :href="mailNichtGefunden">Kontakt aufnehmen</a>
                  </div>
                  <div v-else>
                    Es konnten keine Produkte zu Ihrer Auswahl gefunden werden.
                    <a :href="mailNichtGefunden">Kontakt aufnehmen</a>
                  </div>
                </template>

                <template v-else v-for="(product, i) of filteredProducts" >
                  <div :key="i" class="product d-flex p-3">
                    <div class="product-header" />
                    <CrSquarePicture :source="`img/${product.number}.jpg`" class="product-image" :class="{'product-priority': product.priority}" size="m" alt="" />
                    <div v-if="legenden[product.number]" class="ms-2 product-title">
                      <a :href="`https://www.phoenixcontact.com/product/${product.number}`" target="_blank">
                        <CrTitle :title="`${legenden[product.number].articlename}`" :heading="4" class="product-title mb-4"></CrTitle>
                      </a>
                      <div class="mt-1 mb-3">
                        <span>Artikelnummer: {{product.number}}</span><br>
                        <span>Benötigte Menge: {{product.amount}}</span><br>
                        <span v-html="legenden[product.number].text" />

                      </div>
                      <a class="product-link" :href="`https://www.phoenixcontact.com/product/${product.number}`" target="_blank"><CrButton variant="primary">Zum Produkt</CrButton></a>
                    </div>
                    <div v-else class="ms-2 product-title">
                      <CrTitle :title="`Konnte kein Produkt mit der Aritkelnummer '${product.number}' finden!`" :heading="4" class="product-title mb-4"></CrTitle>
                    </div>
                  </div>
                </template>

              </div>
            </template>

          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>

import Footer from "@/components/Footer";
import {
  CrAlert,
  CrButton, CrIcon,
  CrCheckbox,CrRadioButton,
  CrSquarePicture, CrTable, CrTableBody, CrTitle
} from "@pxc/crisp-oss-ui-components";

export default {
  name: "App",
  components: {
    Footer, CrSquarePicture, CrCheckbox, CrTable, CrTableBody, CrTitle, CrButton, CrIcon, CrAlert, CrRadioButton
  },

  methods: {
    finishSelection() {
      this.selectionFinished = true;
    }
  },

  watch: {
    "selector.inverterManufacturer"() {
      // eslint-disable-next-line
      this.selector.inverterType = "";
      // eslint-disable-next-line
      this.specificationsChecked = false;
      this.selectionFinished = false;
    },
    "selector.inverterType"() {
      // eslint-disable-next-line
      this.specificationsChecked = false;
      this.selectionFinished = false;
      this.filter.active = false;
      this.filter.kabeleinführung = "Sunclix";
      this.filter.erweiterterAnschluss = false;
      this.filter.ableiterklasse = "Irrelevant";
      this.filter.dcfreischaltung = "Keine";
      this.filter.sicherungen = false;
    }
  },

  computed: {
    selectedPhase() {
      // eslint-disable-next-line
      if (this.selector.inverterType == "") {
        return ""
      }
      // eslint-disable-next-line
      return this.selector.inverterType.split("-")[0]
    },
    selectedType() {
      // eslint-disable-next-line
      if (this.selector.inverterType == "") {
        return ""
      }
      // eslint-disable-next-line
      return this.selector.inverterType.split("-")[1]
    },

    filteredProducts() {
      if (this.selector.inverterType == "") {
        return []
      }

      if (!this.filter.active) {
        return this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].products
      }

      return this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].products
        .filter( p => {
          const article = this.legenden[p.number];
          if (!article) {
            return true;
          }

          if (this.filter.kabeleinführung === "Sunclix" && !article.sunclix) {
            return false;
          }
          if (this.filter.kabeleinführung === "Kabelverschraubung" && !article.kabelverschraubung) {
            return false;
          }
          if (this.filter.ableiterklasse === "T1/T2" && !article.t1t2) {
            return false;
          }
          if (this.filter.ableiterklasse === "T2" && !article.t2) {
            return false;
          }
          if (this.filter.dcfreischaltung === "DC-Lasttrennschalter" && !article.dcLastTrenn) {
            return false;
          }
          if (this.filter.dcfreischaltung === "DC-Feuerwehrschalter (Motorbetrieben)" && !article.dcFeuerwehrMotor) {
            return false;
          }
          if (this.filter.dcfreischaltung === "DC-Feuerwehrschalter (Unterspannung)" && !article.dcFeuerwehrUnter) {
            return false;
          }
          if (this.filter.sicherungen && !article.sicherungen) {
            return false;
          }
          if (this.filter.erweiterterAnschluss && !article.erweiterterAnschluss) {
            return false;
          }

          return true;
        })
    },

    subTitle() {
      return `${this.contacts && this.contacts.subtitle || ""}`;
    },

    mailto() {
      return  `mailto:${this.contacts.mailto || "" }`
    },
    mailHersteller() {
      return `${this.mailto}?subject=${ this.contacts.hersteller.betreff || "" }&body=${ this.contacts.hersteller.text || ""}%0D%0A%0D%0AWechselrichterhersteller: %0D%0A%0D%0AVerbesserungsvorschläge (optional): %0D%0A%0D%0A${this.contacts.gruss || ""}`
    },
    mailTyp() {
      return `${this.mailto}?subject=${ this.contacts.typ.betreff || "" }&body=${ this.contacts.typ.text || ""}%0D%0A%0D%0AWechselrichterhersteller: ${this.myData[this.selector.inverterManufacturer].name}%0D%0AWechselrichtertyp: %0D%0A%0D%0AVerbesserungsvorschläge (optional): %0D%0A%0D%0A${this.contacts.gruss || ""}`
    },
    fehlerText() {
      return encodeURIComponent(`Max. Leerlaufspannung:${this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].voltage}\nAnzahl unabhängiger MPP-Tracker:${this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].mppt}\nAnzahl der Strings je MPP-Tracker:${this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].strings}\nMax. nutzbarer Strom je MPP-Tracker:${this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].max}\nMin. erforderliche Eingangspaare (+/-) je MPP-Tracker:${this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].min}`);
    },
    mailFehler() {
      return `${this.mailto}?subject=${ this.contacts.fehler.betreff || "" }&body=${ this.contacts.fehler.text || ""}%0D%0A%0D%0AWechselrichterhersteller: ${this.myData[this.selector.inverterManufacturer].name}%0D%0AWechselrichtertyp: ${this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].name}%0D%0A${this.fehlerText}%0D%0A%0D%0AVerbesserungsvorschläge (optional): %0D%0A%0D%0A${this.contacts.gruss || ""}`
    },
    filterText() {
      return encodeURIComponent(`\nFilter:\nLeitungseinführung: ${this.filter.kabeleinführung}\nAbleiterklasse nach IEC: ${this.filter.ableiterklasse}\nDC-Freischaltung: ${this.filter.dcfreischaltung}\nPV-Sicherungen: ${this.filter.sicherungen? 'Ja' : 'Nein'}`)
    },
    mailNichtGefunden() {
      return `${this.mailto}?subject=${ this.contacts.nichtgefunden.betreff || "" }&body=${ this.contacts.nichtgefunden.text || ""}%0D%0A%0D%0AWechselrichter Hersteller: ${this.myData[this.selector.inverterManufacturer].name}%0D%0AWechselrichter Typ: ${this.myData[this.selector.inverterManufacturer].phases[this.selectedPhase].types[this.selectedType].name}%0D%0A${this.fehlerText}%0D%0A${this.filterText}%0D%0A%0D%0AErgänzende Anmerkungen (optional): %0D%0A%0D%0A${this.contacts.gruss || ""}`;
    }

  },

  created() {
    const href = window.location.href
    fetch(`${href}data/contacts.json`).then( res => {
      res.text().then(data => {
        this.contacts = JSON.parse(data+"");
      })
    })

    fetch(`${href}data/WR-Datenbank.csv` ).then( res => {
      res.text().then( data => {
        const lines = data.replaceAll("\r", "").split("\n")
        for (let i = 0; i < lines.length; i++) {
          const entries = lines[i].split(";")
          const manufacturer = entries[0]
          // eslint-disable-next-line
          if (manufacturer !== "") {
            let foundManufacturer = this.myData.find(x => x.name === manufacturer)
            if (!foundManufacturer) {
              foundManufacturer = {
                name: manufacturer,
                phases: []
              }
              // eslint-disable-next-line
              this.myData.push(foundManufacturer)
            }

            const phase = entries[1];
            // eslint-disable-next-line
            let foundPhase = foundManufacturer.phases.find(x => x.name === phase)
            if (!foundPhase) {
              foundPhase = {
                name: phase,
                types: []
              }
              foundManufacturer.phases.push(foundPhase)
            }

            const info = entries[3];
            const name = entries[2];
            const mppt = entries[4]
            const strings = entries[5];
            const max = entries[6];
            const min = entries[7];
            const voltage = entries[8];
            const products = entries.slice(9).filter(e => e != "");

            foundPhase.types.push({
              info,
              name,
              mppt,
              strings,
              max,
              min,
              voltage,
              products: products.map((p) => {
                const lower = p.toLowerCase();
                const isPriority = lower.substring(0, 1) === "p";
                const splitted = lower.split("x")
                return {
                  priority: isPriority,
                  amount: (""+splitted[0]).substring(isPriority ? 1 : 0),
                  number: (""+splitted[1]).trim()
                }
              })
              //*/
            })
          }
        }
      })
    })

    fetch(`${href}data/WR-Produktlegenden.csv` ).then( res => {
      res.text().then( data => {
        const lines = data.split("\n")

        for (let i = 0; i < lines.length; i++) {
          const entries = lines[i].split(";")
          const articlenumber = entries[0]
          const articlename = entries[1];
          const text = entries[2];
          const sunclix = entries[3] === "y";
          const kabelverschraubung = entries[4] === "y";
          const erweiterterAnschluss = entries[5] === "y";
          const t1t2 = entries[6] === "y";
          const t2 = entries[7] === "y";
          const dcLastTrenn = entries[8] === "y";
          const dcFeuerwehrMotor = entries[9] === "y";
          const dcFeuerwehrUnter = entries[10] === "y";
          const sicherungen = entries[11] === "y";

          this.legenden[articlenumber] = { articlename, text, sunclix, kabelverschraubung, erweiterterAnschluss, t1t2, t2, dcLastTrenn, dcFeuerwehrMotor, dcFeuerwehrUnter, sicherungen };
        }
      })
    })
  },

  data() {
    return {
      selectionFinished: false,
      myData: [],
      contacts: {
        mailto: "",
        hersteller: {
          betreff: "",
          text: ""
        },
        typ: {
          betreff: "",
          text: ""
        },
        fehler: {
          betreff: "",
          text: ""
        },
        nichtgefunden: {
          betreff: "",
          text: ""
        },
        gruss: ""

      },
      filter: {
        active: false,
        kabeleinführung : "Sunclix",
        erweiterterAnschluss: false,
        ableiterklasse: "Irrelevant",
        dcfreischaltung: "Keine",
        sicherungen: false,
      },
      legenden: {},
      selector: {
        inverterManufacturer: "",
        inverterPhase: "",
        inverterType: "",
      },
      specificationsChecked: false,

    }
  }
}
</script>

<style lang="scss">

html, body, #app {
  min-height: 100vh;
}

#app {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

* {
  font-family: "Source Sans Pro", sans-serif !important;
}

footer {
  background: #edf0f3 !important;
  text-align: center !important;
}

.footer-address {
  font-size: 1rem;
}

.footer-actions a,.footer-actions a {
  color: #007C84 !important;
}

.footer-actions img {
  height: 16px;
  width: 16px;
  color: #0098a1 !important;
}

.footer-actions .ico-telephone {
  margin-bottom: -4px;
  margin-right: 12px;
  display: inline-block;
  height: 16px;
  width: 16px;
  -webkit-mask: url("~/public/phone.svg") no-repeat center;
  mask: url("~/public/phone.svg") no-repeat center;
  background-color: #0098a1;
}

.footer-actions .ico-mail {
  margin-bottom: -4px;
  margin-right: 12px;
  display: inline-block;
  height: 16px;
  width: 16px;
  -webkit-mask: url("~/public/envelope.svg") no-repeat center;
  mask: url("~/public/envelope.svg") no-repeat center;
  background-color: #0098a1;
}

.footer-actions .ico-datasheet {
  margin-bottom: -4px;
  margin-right: 12px;
  display: inline-block;
  height: 16px;
  width: 16px;
  -webkit-mask: url("~/public/file-lines.svg") no-repeat center;
  mask: url("~/public/file-lines.svg") no-repeat center;
  background-color: #0098a1;
}

/*
[class*=" ico-"], [class^=ico-] {
  font-family: phoenix-contact-icons!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-datasheet:before {
  content: "\E941";
}
*/

a {
  font-weight: 600 !important;
  color: #0098a1 !important;
  text-decoration: none !important;
  background-color: transparent !important;
  white-space: nowrap;
}


.table-responsive {
  overflow-x: scroll !important;
}

.table-responsive .table {
  max-width: 100%;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.row {
  vertical-align: middle;
}

.submit-btn {
  display: flex;
  align-items: center;
}

.submit-btn .cr-loading-spinner-v4-2-0 {
  margin-right: 16px;
}

.cr-radio-button-v5-3-0 {
  display: inline-block !important;
  padding-right: 16px;
}

.product {
  position: relative;
}

.product-header {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: rgb(242, 244, 246);
}

.product-title {
  z-index: 80;
}

.product-image {
  position: relative;
  z-index: 40;
}

.product-image.product-priority:before {
  content: "Empfohlen";
  letter-spacing: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 18.2px;
  line-height: 20.8px;
  background: rgb(255,168,0);
  font-size: 14px;
  color: black;
  text-align: center
}

.product-link {
  float: right;
  //position: absolute;
  //right: 1rem;
  //bottom: 1rem;
}

.product {
  border-left: 1px solid rgb(162,167,169);
  border-top: 1px solid rgb(162,167,169);
  border-right: 1px solid rgb(162,167,169);
  z-index: 10;
}

.product:hover {
  box-shadow: 0 4px 8px rgb(32 32 32 / 30%);
  z-index: 20;
}
.product:hover .product-title h4{
  text-decoration: underline;
}

.product:last-child {
  border-bottom: 1px solid rgb(162,167,169);
}

.filter > div {
  border-top: 1px solid rgb(162,167,169);
  border-left: 1px solid rgb(162,167,169);
  border-right: 1px solid rgb(162,167,169);
  border-bottom: 1px solid rgb(162,167,169);
  display: flex;
  flex-direction: column;
}
.filter > div:last-child {
  //border-right: 1px solid rgb(162,167,169);
}


.accordion-header {
  position: relative;
}

.accordion .accordion-ico {
  position: absolute;
  top: 18px;
  left: 14px;
  //margin-top: -30px;
}
.accordion .accordion-ico svg {
  transform: rotate(270deg);
  transform-origin: center;
  transition: transform 400ms linear;
}

.accordion, .accordion-body {
  transition: all 400ms linear;
}

.accordion .accordion-body {
  transform: scaleY(0);
  transition: transform 400ms linear;
  transform-origin: top;
}
.accordion.accordion-open .accordion-body {
  transform: scaleY(1);
}

.accordion.accordion-open .accordion-ico svg {
  transform: rotate(90deg);
}

@keyframes checkboxBG {
  0% { border-color: #0098a1; }
  50% { border-color: orange; }
  /*25% { background: orange; }
  100% { background: orange; }*/
}

input[type="checkbox"]:not(:checked) + label > .cr-checkmark-v5-3-0 {
  //animation: checkboxBG 2s infinite linear;
}

.banner-area {
  margin-left: -12px;
  margin-right: -12px;
  min-height: 15vh;
}
@media only screen and (min-width: 480px) {
  .banner-area {
    min-height: 20vh;
  }
}
@media only screen and (min-width: 960px) {
  .banner-area {
    min-height: 40vh;
  }
}


@media (max-width: 720px) {
  .product {
    flex-direction: column;
  }

  .product-title {
    margin-top: 24px;
    margin-left: -2px;
  }
}

.banner-area .banner-text {
  color: #007C84;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}

.banner-area .banner-text h1 {
  font-size: 48px !important;
}

.banner {
  width: 100%;
  height: auto;
  max-height: 75vh;
  object-fit: cover;
}

.white-space {
  white-space: pre-line;
}

.filter {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
}

.filter > div {
  flex-grow: 1;
}
.cr-icon-v5-3-0.d-inline-block {
  margin-left: 4px;
}
</style>
